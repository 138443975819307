<script>
import WidgetClinics from '@/components/Widget/WidgetClinics.vue'
import WidgetDoctors from '@/components/Widget/WidgetDoctors.vue'
import WidgetPosts from '@/components/Widget/WidgetPosts.vue'
import WidgetWdwt from '@/components/Widget/WidgetWdwt.vue'
import WidgetUsers from '@/components/Widget/WidgetUsers.vue'
import WidgetPages from '@/components/Widget/WidgetPages.vue'

export const CARD_POSTS = 'posts'
export const CARD_CLINICS = 'clinics'
export const CARD_DOCTORS = 'doctord'
export const CARD_WDWT = 'wdwt'
export const CARD_USERS = 'users'
export const CARD_PAGES = 'pages'

const extras = {
  [CARD_POSTS]: {
    view: WidgetPosts
  },
  [CARD_CLINICS]: {
    view: WidgetClinics
  },
  [CARD_DOCTORS]: {
    view: WidgetDoctors
  },
  [CARD_WDWT]: {
    view: WidgetWdwt
  },
  [CARD_USERS]: {
    view: WidgetUsers
  },
  [CARD_PAGES]: {
    view: WidgetPages
  }
}

export default {
  props: {
    name: {
      type: String
    }
  },
  render (h) {
    const extra = extras[this.name]
    if (!extra) {
      throw Error(`Details for dashboard item "${this.name}" not found`)
    }
    const { view } = extra
    if (!view) {
      throw Error(`View for dashboard item "${this.name}" not found`)
    }
    return h(view, {
      props: this.$attrs,
      attrs: this.$attrs,
      on: this.$listeners,
      scopedSlots: this.$scopedSlots
    })
  }
}
</script>
