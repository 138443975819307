<template>
  <AutocompleteAsync
    v-on="$listeners"
    v-bind="attrs"
  />
</template>

<script>
import AutocompleteAsync from '../Cart/AutocompleteAsync.vue'
export const types = {
  SYMPTOMS: 'symptoms',
  PROCEDURES: 'procedures',
  DISEASES: 'diseases',
}

const services = {
  [types.SYMPTOMS]: require('@/services/symptoms').default,
  [types.PROCEDURES]: require('@/services/procedures').default,
  [types.DISEASES]: require('@/services/diseases').default,
}


export default {
  components: {
    AutocompleteAsync
  },
  props: {
    type: String,
    validator: (prop) => Object.keys(types).includes(prop),
    required: true,
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        itemText: 'name',
        itemValue: 'id',
        load: (...args) => this.load(...args)
      }
    }
  },
  methods: {
    async load (params) {
      const api = services[this.type]
      const { items } = await api.getAll(params)
      return items
    }
  }
}
</script>
