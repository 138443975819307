<template>
  <widget-card-base title="Что мы лечим">
    <div class="dashboard-wdwt d-flex d-sm-block d-lg-block d-xl-block justify-space-between">
      <div v-for="item in items" :key="item.title">
        <h4>
          <router-link :to="item.route">{{ item.title }}</router-link>
        </h4>
        <wdwt-select
          placeholder="Введите название"
          :type="item.type"
          class="d-none d-xl-block d-lg-block d-sm-block"
          @input="item.select"
        />
      </div>
    </div>
  </widget-card-base>
</template>

<script>
import WdwtSelect, { types } from '@/components/Wdwt/WdwtSelect.vue'
import WidgetCardBase from '@/components/Widget/WidgetCardBase.vue'

export default {
  components: {
    WdwtSelect,
    WidgetCardBase
  },
  computed: {
    items () {
      return [
        {
          type: types.SYMPTOMS,
          title: 'Симптомы',
          route: { name: 'Symptoms' },
          select: (item) => this.input({name: 'Symptom', params: { id: item?.id } })
        },
        {
          type: types.PROCEDURES,
          title: 'Процедуры',
          route: { name: 'Procedures' },
          select: (item) => this.input({ name: 'Procedure', params: { id: item?.id } })
        },
        {
          type: types.DISEASES,
          title: 'Заболевания',
          route: { name: 'Diseases' },
          select: (item) => this.input({ name: 'Disease', params: { id: item?.id } })
        }
      ]
    }
  },
  methods: {
    input (route) {
      return this.$router.push(route)
    }
  }
}
</script>
