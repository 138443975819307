<template>
  <widget-card-base
    title="Клиники"
    :url="{ name: 'Clinics' }"
  >

    <div class="text-center mt-4" v-if="req.pending">
      <v-progress-circular indeterminate />
    </div>
    <v-alert v-else-if="req.error" type="error">{{ req.error.message }}</v-alert>
    <widget-items-list v-else-if="req.data && req.data.length" :items="req.data" key-field="id" v-slot="{ item }">
      <router-link :to="{ name: 'Clinic', params: { id: item.id } }" class="dashboard-clinics__link" >
        {{ item.name }}
      </router-link>
    </widget-items-list>
  </widget-card-base>
</template>

<script>
import clinicsService from '@/services/clinics'
import { createRequest } from '@/utils/requestsFactory'
import WidgetCardBase from '@/components/Widget/WidgetCardBase.vue'
import WidgetItemsList from '@/components/Widget/WidgetItemsList.vue'

export default {
  components: {
    WidgetCardBase,
    WidgetItemsList,
  },
  data () {
    return {
      req: createRequest(async () => {
        const { items } = await clinicsService.getAll()
        return items.filter((clinic) => !clinic.private)
      })
    }
  },
  mounted () {
    return this.req.run()
  }
}
</script>
